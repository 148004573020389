const BalanceScale = require('react-icons/fa').FaBalanceScale;
const Envelope = require('react-icons/fa').FaEnvelope;
const ExclamationMark = require('react-icons/fa').FaExclamationCircle;
const Gavel = require('react-icons/fa').FaGavel;
const Home = require('react-icons/fa').FaHome;
const LifeRing = require('react-icons/fa').FaLifeRing;
const Lightbulb = require('react-icons/fa').FaLightbulb;
const QuestionMark = require('react-icons/fa').FaRegQuestionCircle;
const Tractor = require('react-icons/fa').FaTractor;
const Truck = require('react-icons/fa').FaTruck;
const Wrench = require('react-icons/fa').FaWrench;

const Calendar = require('react-icons/go').GoCalendar;
const Checklist = require('react-icons/go').GoChecklist;
const Dashboard = require('react-icons/go').GoDashboard;
const Eye = require('react-icons/go').GoEye;
const Mail = require('react-icons/go').GoMail;

const navigate = require('gatsby').navigate;

const variables = require('src/sass/_variables.scss');

module.exports = {
  config: {
    bookTestBtn: {
      text: 'Book Your Test Online',
      onClick: navigate.bind(this,'/test-centre/bookings')
    }
  },
  pages: {
    home: {
      order: 0,
      path: '/',
      icon: {
        component: Home,
        text: 'Home'
      },
      title: 'Home - Mallow Commercials CVRT Test Centre Cork',
      config: {
        logoRow: {
          bookTestBtn: {
            text: 'Book Your Test Today',
            onClick: navigate.bind(this,'/test-centre/bookings')
          }
        },
        welcomeRow: {
          headingPrimary: 'Mallow Commercials Ltd.',
          headingSecondary: 'Heavy & Light Commercial Vehicle Testing',
          text: `
            Mallow Commercials Ltd. is an approved HGV and LGV CVRT Vehicle Test Centre.
            As well as CVRT Testing we also provide a full range of services including
            Tachograph and Speed Limiter Calibrations, Plating and Safety Voluntary
            Testing at our modern premises in Mallow, Co. Cork.
          `
        },
        keyServicesRow: {
          headingPrimary: 'Our Key Services',
          headingSecondary: '',
          servicesPerRow: 3,
          servicesIconColor: variables.colorGreen,
          services: {
            cvrtTesting: {
              order: 0,
              icon: {
                component: Checklist,
                text: 'CVRT Testing'
              },
              text: 'Heavy & light commercial vehicle testing'
            },
            safetyInspections: {
              order: 1,
              icon: {
                component: Eye,
                text: 'Safety Inspections'
              },
              text: '12 week safety inspections of vehicles'
            },
            speedLimiter: {
              order: 2,
              icon: {
                component: ExclamationMark,
                text: 'Speed Limiter'
              },
              text: 'NSAI approved speed limiter verification centre'
            },
            salesAndRentals: {
              order: 3,
              icon: {
                component: Gavel,
                text: 'Van Sales & Van Rentals'
              },
              text: 'A collection of vans for sale & hire'
            },
            tractorAndTrailer: {
              order: 4,
              icon: {
                component: Tractor,
                text: 'Tractor & Trailer'
              },
              text: 'Inspections and brake tests on all agricultural machinery'
            },
            usedParts: {
              order: 5,
              icon: {
                component: LifeRing,
                text: 'ADR Approved'
              },
              text: 'ADR Approved Test Centre'
            },
            tachographCentre: {
              order: 6,
              icon: {
                component: Dashboard,
                text: 'Tachograph Centre'
              },
              text: 'NSAI approved tachograph service and calibration centre'
            },
            plating: {
              order: 7,
              icon: {
                component: BalanceScale,
                text: 'Plating'
              },
              text: 'NSAI approved vehicle weights and dimensions plating'
            },
            fleetMaintenance: {
              order: 8,
              icon: {
                component: Wrench,
                text: 'Fleet Maintenance'
              },
              text: 'Dedicated key account manager'
            },
            headlightAlignment: {
              order: 9,
              icon: {
                component: Lightbulb,
                text: 'Headlight Alignment'
              },
              text: 'Vehicle headlamp alignment'
            },
            collectDropBack: {
              order: 10,
              icon: {
                component: Truck,
                text: 'Drop off and Collect facility'
              },
              text: 'Drop off your vehicle or trailer at our site at a time that suits you'
            },
            instantEmailNotification: {
              order: 11,
              icon: {
                component: Mail,
                text: 'Instant Email Notification'
              },
              text: 'Test results emailed to you directly'
            }
          }
        }
      }
    },
    testCentre: {
      order: 1,
      path: '/test-centre',
      icon: {
        component: Truck,
        text: 'CVRT Test Centre',
      },
      title: 'Test Centre - Mallow Commercials CVRT Test Centre Cork',
      config: {
        topRow: {
          bookTestBtn: {
            primaryColor: variables.colorWhite,
            secondaryColor: variables.colorOrange,
            text: 'Book Your Test Today',
            onClick: navigate.bind(this, '/test-centre/bookings')
          }
        },
        middleRowOne: {
          headingPrimary: 'CVRT Testing',
          headingSecondary: 'Heavy & Light Commercial Vehicle Testing',
          textOne: `
            All commercial vehicles over one year old require a Certificate of Roadworthiness (CRWs), this
            includes vehicles such as ambulances and buses with more that eight passenger seats. The CRW is
            attained by successfully completing the Commercial Vehicle Roadworthiness Test (CVRT).
          `,
          textTwo: `
            There are two types of commercial vehicle tests, one for Light Commercial Vehicles (LCVs)
            and one for Heavy Commercial Vehicles (HCVs). The purpose of the test is to ensure that
            these vehicles are kept in good condition throughout their entire life.
          `
        },
        middleRowTwo: {
          headingPrimary: 'CVRT Price List (ex VAT)',
          headingSecondary: '',
          prices: [
            {
              order: 0,
              vehicleClass: 'Vehicles having a design gross weight not exceeding 3,500 kilograms',
              testFee: 70.86,
              levy: 24.00,
              retestFee: 35.43
            },
            {
              order: 1,
              vehicleClass: 'Motor caravans with two axles',
              testFee: 70.86,
              levy: 6.00,
              retestFee: 35.43
            },
            {
              order: 2,
              vehicleClass: 'Vehicles having a design gross weight between 3,500 kg and 7,500 kg',
              testFee: 94.52,
              levy: 52.00,
              retestFee: 47.26
            },
            {
              order: 3,
              vehicleClass: 'Vehicles having a design gross weight exceeding 7,500 kilograms with 2 axles',
              testFee: 118.16,
              levy: 53.00,
              retestFee: 59.08
            },
            {
              order: 4,
              vehicleClass: 'Vehicles having a design gross weight exceeding 7,500 kilograms with 3 axles',
              testFee: 147.67,
              levy: 54.00,
              retestFee: 73.83
            },
            {
              order: 5,
              vehicleClass: 'Vehicles having a design gross weight exceeding 7,500 kilograms with 4 or more axles',
              testFee: 165.38,
              levy: 55.00,
              retestFee: 82.69
            },
            {
              order: 6,
              vehicleClass: 'Trailers having a design gross weight exceeding 3,500 kilograms',
              testFee: 106.30,
              levy: 35.65,
              retestFee: 53.15
            },
            {
              order: 7,
              vehicleClass: 'Ambulances',
              testFee: 94.52,
              levy: 50.48,
              retestFee: 47.26
            },
            {
              order: 8,
              vehicleClass: 'Vehicles used for the carriage of passengers between 8 and 14 seats',
              testFee: 147.67,
              levy: 52.33,
              retestFee: 73.83
            },
            {
              order: 9,
              vehicleClass: 'Vehicles used for the carriage of passengers with 14 seats or more',
              testFee: 147.67,
              levy: 50.33,
              retestFee: 73.83
            },
            {
              order: 10,
              vehicleClass: 'Motor caravans with three or more axles',
              testFee: 73.17,
              levy: 24.00,
              retestFee: 35.43
            },
          ]
        },
        bottomRow: {
          headingPrimary: 'Retests',
          headingSecondary: '',
          text: `
            All re-tests must be completed within 21 calendar days and within 4,000 kms of the initial test
            or you’ll need to repeat the full test. A visual re-test is free, but a re-test that requires a
            test lane has a fee that is half of the normal test fee.
          `
        },
      },
      pages: {
        bookings: {
          order: 0,
          path: '/test-centre/bookings',
          icon: {
            component: Calendar,
            text: 'Online Booking'
          },
          title: 'Book A Test - Mallow Commercials CVRT Test Centre Cork',
          config: {
            fullTest: {
              vehicleClasses: {
                'Select...': {
                  testFee: null,
                  levy: null
                },
                'Vehicle gross weight < 3,500kg': {
                  testFee: 70.86,
                  levy: 24.0
                },
                'Vehicle gross weight between 3,500kg and 7,500kg': {
                  testFee: 94.52,
                  levy: 52.0
                },
                'Vehicle gross weight > 7,500kg with 2 axles': {
                  testFee: 118.16,
                  levy: 53.0
                },
                'Vehicle gross weight > 7,500kg with 3 axles': {
                  testFee: 147.67,
                  levy: 54.0
                },
                'Vehicle gross weight > 7,500kg with 4 axles or more': {
                  testFee: 165.38,
                  levy: 55.0
                },
                'Vehicle used for passengers with between 8 and 14 seats': {
                  testFee: 147.67,
                  levy: 50.33
                },
                'Vehicle used for passengers with 14 seats or more': {
                  testFee: 147.67,
                  levy: 52.33
                },
                'Trailer gross weight > 3,500kg': {
                  testFee: 106.30,
                  levy: 35.65
                },
                'Motor caravans with 2 axles': {
                  testFee: 70.86,
                  levy: 6.0
                },
                'Motor caravans with 3 axles or more': {
                  testFee: 88.58,
                  levy: 6.0
                },
                'Ambulances': {
                  testFee: 94.52,
                  levy: 50.48
                }
              }
            },
            preTest: {
              vehicleClasses: {
                'Select...': {
                  testFee: null,
                  levy: null
                },
                'Vehicle gross weight < 3,500kg': {
                  testFee: 70.86,
                  levy: 0.0
                },
                'Vehicle gross weight between 3,500kg and 7,500kg': {
                  testFee: 94.52,
                  levy: 0.0
                },
                'Vehicle gross weight > 7,500kg with 2 axles': {
                  testFee: 118.16,
                  levy: 0.0
                },
                'Vehicle gross weight > 7,500kg with 3 axles': {
                  testFee: 147.67,
                  levy: 0.0
                },
                'Vehicle gross weight > 7,500kg with 4 axles or more': {
                  testFee: 165.38,
                  levy: 0.0
                },
                'Vehicle used for passengers with between 8 and 14 seats': {
                  testFee: 147.67,
                  levy: 0.0
                },
                'Vehicle used for passengers with 14 seats or more': {
                  testFee: 147.67,
                  levy: 0.0
                },
                'Trailer gross weight > 3,500kg': {
                  testFee: 106.30,
                  levy: 0.0
                },
                'Motor caravans with 2 axles': {
                  testFee: 70.86,
                  levy: 0.0
                },
                'Motor caravans with 3 axles or more': {
                  testFee: 88.58,
                  levy: 0.0
                },
                'Ambulances': {
                  testFee: 94.52,
                  levy: 0.0
                }
              }
            }
          }
        },

        inspections: {
          order: 1,
          path: '/test-centre/inspections',
          icon: {
            component: Eye,
            text: 'Pre-Inspections'
          },
          title: 'Inspections - Mallow Commercials CVRT Test Centre Cork',
          config: {
            topRow: {
              bookTestBtn: {
                primaryColor: variables.colorWhite,
                secondaryColor: variables.colorOrange,
                text: 'Book Your Test Today',
                onClick: navigate.bind(this, '/test-centre/bookings')
              }
            },
            middleRowOne: {
              headingPrimary: 'Inspections',
              headingSecondary: 'Heavy & Light Commercial Vehicle Inspections',
              levels: [
                {
                  order: 0,
                  heading: 'Level 1',
                  bullets: [
                    {
                      order: 0,
                      text: 'Visual check of under-body including brake components, steering components and chassis/suspension condition'
                    },
                    {
                      order: 1,
                      text: 'Walk around inspection of exterior cab and body work'
                    },
                    {
                      order: 2,
                      text: 'Brakes tested on presented vehicle weights'
                    }
                  ]
                },
                {
                  order: 1,
                  heading: 'Level 2',
                  bullets: [
                    {
                      order: 0,
                      text: 'As level 1'
                    },
                    {
                      order: 1,
                      text: 'Jacking of steering axles'
                    },
                    {
                      order: 2,
                      text: 'Shaker plate check on suspension'
                    }
                  ]
                },
                {
                  order: 2,
                  heading: 'CVRT Pre-Test',
                  bullets: [
                    {
                      order: 0,
                      text: 'Headlight alignment'
                    },
                    {
                      order: 1,
                      text: 'Emissions test'
                    },
                    {
                      order: 2,
                      text: 'Slip slide test'
                    },
                    {
                      order: 3,
                      text: 'Gross vehicle weight brake test'
                    }
                  ]
                }
              ]
            },
            middleRowTwo: {
              headingPrimary: 'Inspection Price List (ex VAT)',
              headingSecondary: '',
              prices: [
                {
                  order: 0,
                  vehicleType: "LGV (Vans, 4x4's, Light Trucks)",
                  levelOne: 40,
                  levelTwo: 50,
                  preTest: 68
                },
                {
                  order: 1,
                  vehicleType: "Trailer and Semi's",
                  levelOne: 60,
                  levelTwo: 80,
                  preTest: 100
                },
                {
                  order: 2,
                  vehicleType: "2 axle HGV above 7,500Kg's",
                  levelOne: 60,
                  levelTwo: 80,
                  preTest: 120
                },
                {
                  order: 3,
                  vehicleType: "3 axle HGV above 7,500Kg's",
                  levelOne: 70,
                  levelTwo: 90,
                  preTest: 130
                },
                {
                  order: 4,
                  vehicleType: "4 axle HGV above 7,500Kg's",
                  levelOne: 80,
                  levelTwo: 100,
                  preTest: 140
                },
                {
                  order: 5,
                  vehicleType: 'Buses',
                  levelOne: 60,
                  levelTwo: 80,
                  preTest: 140
                },
              ]
            },
            bottomRow: {
              headingPrimary: 'Agricultural Inspection Price List (ex VAT)',
              headingSecondary: '',
              prices: [
                {
                  order: 0,
                  vehicleType: 'Tractor',
                  brakeTest: 50,
                  brakeTestAndInspection: 80
                },
                {
                  order: 1,
                  vehicleType: 'Trailer',
                  brakeTest: 50,
                  brakeTestAndInspection: 80
                },
                {
                  order: 2,
                  vehicleType: 'Other Machinery',
                  brakeTest: 50,
                  brakeTestAndInspection: 80
                }
              ]
            }
          }
        }
      }
    },
    repairsAndServices: {
      order: 2,
      path: '/commercials/repairs-&-services',
      icon: {
        component: Wrench,
        text: 'Repairs & Servicing'
      },
      title: 'Repairs & Services - Mallow Commercials CVRT Test Centre Cork',
      config: {
        middleRow: {
          headingPrimary: 'Repairs, Servicing & Fleet Maintenance',
          headingSecondary: 'Heavy and Light Commercial Vehicle Repairs,',
          headingTertiary: 'Servicing & Fleet Maintenance',
          textOne: `
            At Mallow Commercials we provide servicing and repairs to all makes and models
            of cars, vans and jeeps. We also have a comprehensive diagnostic facility which
            enables us to carry out full diagnostic tests on a wide range of vehicles.
            Our LGV repair garage has three bays where we carry out service and repairs on
            all makes of vehicles. We also have two HGV bays where we can service and repair
            all HGV’s including buses and trailers.
          `,
          textTwo: `
            Our workshop is approved by the Society of the Irish Motor Industry (SIMI).
            We stock and fit a wide range of parts for all makes of LGV and HGV vehicles.
            Our repair facility proves very popular and convenient with some customers as
            they can drop there vehicle to be tested and repaired if required on the same
            day, this saves them valuable time on their busy schedules.
          `
        },
        bottomRow: {
          headingPrimary: 'Service Checks Include',
          headingSecondary: '',
          services: [
            {
              order: 0,
              bullets: [
                {
                  order: 0,
                  text: 'Oil change'
                },
                {
                  order: 1,
                  text: 'Oil filter change'
                },
                {
                  order: 2,
                  text: 'Air filter inspection'
                },
                {
                  order: 3,
                  text: 'Fuel filter inspection'
                },
                {
                  order: 4,
                  text: 'Full tyre inspection'
                }
              ]
            },
            {
              order: 1,
              bullets: [
                {
                  order: 0,
                  text: 'Brake inspection'
                },
                {
                  order: 1,
                  text: 'Brake fluid top up'
                },
                {
                  order: 2,
                  text: 'Suspension inspection'
                },
                {
                  order: 3,
                  text: 'Steering fluid top up'
                },
                {
                  order: 4,
                  text: 'Handbrake adjustment'
                }
              ]
            },
            {
              order: 2,
              bullets: [
                {
                  order: 0,
                  text: 'Lights & wipers check'
                },
                {
                  order: 1,
                  text: 'Refill washer fluid'
                },
                {
                  order: 2,
                  text: 'Diagnostic check'
                },
                {
                  order: 3,
                  text: 'Test drive'
                },
                {
                  order: 4,
                  text: 'Battery check'
                }
              ]
            }
          ]
        }
      }
    },
    salesAndRentals: {
      order: 3,
      path: '/commercials/sales-&-rentals',
      icon: {
        component: Gavel,
        text: 'Van Sales & Van Rental'
      },
      title: 'Sales & Rentals - Mallow Commercials CVRT Test Centre Cork',
    },
    contact: {
      order: 4,
      path: '/contact',
      icon: {
        component: Envelope,
        text: 'Contact Us'
      },
      title: 'Contact Us - Mallow Commercials CVRT Test Centre Cork',
    },
    faqs: {
      order: 5,
      path: '/faqs',
      icon: {
        component: QuestionMark,
        text: "FAQ's"
      },
      title: "FAQ's - Mallow Commercials CVRT Test Centre Cork",
    }
  },
  footer: {
    top: {
      headingPrimary: 'Mallow Test Centre',
      headingSecondary: 'Location',
      map: {
        latitude: 52.1229909,
        longitude: -8.6682118,
        url: 'https://goo.gl/maps/KwddcUdZR7C2'
      }
    }
  },
  contactDetails: {
    emailInquiryAddress: 'james@mallowcommercials.ie',
    snapchatUsername: 'jossysull',
    facebookAddress: 'https://www.facebook.com/MallowTestCentre',
    instagramAddress: 'https://www.instagram.com/',
    twitterAddress: 'https://www.twitter.com/MallowTestCentr'
  },
  seo: {
    description: 'Commercial vehicle testing, repairs and sales in Quartertown Mallow, County Cork, Munster',
    image: require('./resources/images/test-centre/logo.png'),
    keywords: 'commercial centre center cork cvrt doe mallow munster repairs test vehicle',
    title: 'Mallow Commercials CVRT Test Centre Cork',
    url: 'https://www.mallow-commercials.ie'
  }
};
