import React from "react";
import PropTypes from "prop-types";

import css from "./index.module.scss";


const Backdrop = props => {
  const cssClass = props.visible ? "visible" : "hidden";
  return (
    <div className={[css[cssClass], props.className].join(" ")} style={props.style} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

Backdrop.defaultProps = {
  className: "",
  visible: false
};

Backdrop.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onClick: PropTypes.func,
  visible: PropTypes.bool,
  style: PropTypes.object
};

export default Backdrop;