import React from "react";
import PropTypes from "prop-types";

import Column from "src/components/Column";

import css from "./index.module.scss";


const Header = props => {
  return (
      <Column direction="cc" className={[css.header, props.className].join(" ")} style={props.style}>
        <h1>{props.primaryText}</h1>
        {props.hasOwnProperty("secondaryText") && props.secondaryText !== "" ? <h3>{props.secondaryText}</h3>: ""}
        {props.hasOwnProperty("tertiaryText") && props.tertiaryText !== "" ? <h3>{props.tertiaryText}</h3>: ""}
        <hr/>
      </Column>
  );
};

Header.defaultProps = {
  className: ""
};

Header.propTypes = {
  className: PropTypes.string,
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  tertiaryText: PropTypes.string,
  style: PropTypes.object
};

export default Header;
