import React from "react";

import Row from "src/components/Row";

import css from "./index.module.scss";

export default class Banner extends React.Component {
    state = {
        deferredPrompt: null,
        display: 'none'
    }

    componentDidMount() {
        window.addEventListener('beforeinstallprompt', (deferredPrompt) => {
            deferredPrompt.preventDefault();
            this.setState({deferredPrompt, display: 'flex'})
        });
    }

    handleBtnClickEvent() {
        const {deferredPrompt} = this.state;
        deferredPrompt.prompt();
        this.setState({display: 'none'});
        deferredPrompt.userChoice.then(() => this.setState({deferredPrompt: null}))
    }

    render() {
        const {display} = this.state;
        return (
            <Row className={css.banner} direction='cc' style={{display}}>
                <h2 onClick={this.handleBtnClickEvent.bind(this)}>
                    Download Our App Today!!!
                </h2>
            </Row>
        )
    }
}
