import React from "react";
import PropTypes from "prop-types";

import css from "./index.module.scss";


const Row = props => {
  return (
      <div className={[css[props.direction], props.className].join(" ")} style={props.style} onClick={props.onClick}>
        {props.children}
      </div>
  );
};

Row.defaultProps = {
  className: "",
  direction: "tl"
};

Row.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.oneOf(["bc", "bl", "br", "cc", "cl", "cr", "tc", "tl", "tr"]),
  onClick: PropTypes.func,
  style: PropTypes.object
};

export default Row;
