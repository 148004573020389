import React from "react";
import PropTypes from "prop-types";

import css from "./index.module.scss";


class Tab extends React.Component {
  
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.props !== nextProps;
  }
  
  render() {
    return (
      <div className={[css[this.props.type], this.props.className].join(" ")} style={this.props.style}>
        {this.props.children}
      </div>
    );
  }
}

Tab.defaultProps = {
  className: "",
  type: "default"
};

Tab.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.oneOf(["default", "inverted"])
};

export default Tab;