import React from "react";
import PropTypes from "prop-types";
import {MobileView} from "react-device-detect";

import Banner from "src/containers/Banner";
import Card from "src/components/Card";
import Column from "src/components/Column";
import DesktopNavigation from "src/containers/DesktopNavigation";
import Footer from "src/containers/Footer";
import MobileNavigation from "src/containers/MobileNavigation";

import css from "./index.module.scss";


const Layout = props => {
  return (
    <React.Fragment>
      <Card className={css.cardLayout}>
        <Column direction="cc">
          <DesktopNavigation {...props}/>
          <MobileNavigation {...props}/>
          {props.children}
          <Footer {...props}/>
        </Column>
      </Card>
      <MobileView>
        <Banner/>
      </MobileView>
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
