import React from "react";
import PropTypes from "prop-types";
import {Link} from "gatsby";
import {FaMapMarkerAlt, FaPhone, FaFacebook, FaInstagram, FaSnapchat, FaTwitter} from "react-icons/fa";
import {GoMail} from "react-icons/go";

import Column from "src/components/Column";
import Header from "src/components/Header";
import Map from "src/components/Map";
import Row from "src/components/Row";

import { contactDetails, footer, pages } from "src/config";
import css from "./index.module.scss";


const Footer = props => {

  const renderSiteMap = () => {
    const orderedPageKeys = Object.keys(pages).sort((a, b) => pages[a].order - pages[b].order);
    return (
      <ul>
        {orderedPageKeys.map(key => {
          const page = pages[key];
          return (
            <li key={page.path}>
              {renderIconComponent(page.icon.component)}&nbsp;&nbsp;
              <Link to={page.path}>{page.icon.text}</Link>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderIconComponent = Component => {
    return <Component className={css.footerIcon}/>;
  };

  return (
    <Column className={[css.footer, props.className].join(" ")} direction="cc"  style={props.style}>
      <Column className={css.footerTop} direction="cc">
        <Header primaryText={footer.top.headingPrimary} secondaryText={footer.top.headingSecondary}/>
        <Map className={css.map} latitude={footer.top.map.latitude} longitude={footer.top.map.longitude} openURL={footer.top.map.url} />
      </Column>

      <Column className={css.footerMiddle} direction="cc">
        <Column direction="cc" style={{flex: 1}}>
          <h4>Contact Details</h4>
          <ul>
            <li>
              <Row>
                <FaMapMarkerAlt className={css.footerIcon}/>
                <Column>
                  <p>&nbsp;Mallow Commercials Ltd.</p>
                  <p>&nbsp;Lower Quartertown,</p>
                  <p>&nbsp;Mallow,</p>
                  <p>&nbsp;Co. Cork</p>
                </Column>
              </Row>
            </li>
            <li>
              <FaPhone className={css.footerIcon}/>&nbsp;
              <a href="tel:+353-22-21614">022 21614</a>
            </li>
            <li>
              <GoMail className={css.footerIcon}/>&nbsp;
              <a href={`mailto:${contactDetails.emailInquiryAddress}`}>Email Us</a>
            </li>
          </ul>
        </Column>

        <div className={css.verticalRule}></div>

        <Column direction="cc" style={{flex: 1}}>
          <h4>Sitemap</h4>
          {renderSiteMap()}
        </Column>

        <div className={css.verticalRule}></div>

        <Column direction="cc" style={{flex: 1}}>
          <h4>Social Media</h4>
          <ul>
            <li><FaSnapchat className={css.footerIcon}/>&nbsp;&nbsp;
              <a href={`https://www.snapchat.com/add/${contactDetails.snapchatUsername}`} target="_blank" rel="noopener noreferrer">Snapchat</a>
            </li>
            <li>
              <FaFacebook className={css.footerIcon}/>&nbsp;&nbsp;<a href={contactDetails.facebookAddress} target="_blank" rel="noopener noreferrer">Facebook</a>
            </li>
            <li>
              <FaInstagram className={css.footerIcon}/>&nbsp;&nbsp;<a href={contactDetails.instagramAddress} target="_blank" rel="noopener noreferrer">Instagram</a>
            </li>
            <li>
              <FaTwitter className={css.footerIcon}/>&nbsp;&nbsp;<a href={contactDetails.twitterAddress} target="_blank" rel="noopener noreferrer">Twitter</a>
            </li>
          </ul>
        </Column>
      </Column>

      <Column className={css.footerBottom} direction="cc">
        <hr className={css.horizontalRule}/>
        <p>
          &copy; Mallow Commercials Ltd. 2019 | Website Design and Development by&nbsp;
          <a href="https://www.linkedin.com/in/kfcobrien/" target="_blank" rel="noopener noreferrer">Kevin O'Brien</a>
        </p>
      </Column>
    </Column>
  );
};

Footer.defaultProps = {
  className: ""
};

Footer.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Footer;
