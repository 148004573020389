import React from "react";
import PropTypes from "prop-types";
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from 'react-google-maps';

import css from "./index.module.scss";


const Map = ({className, latitude, longitude, icon, openURL}) => {
  
  const MallowCommercialsMap = withScriptjs(withGoogleMap(props => (
    <GoogleMap
      defaultCenter={{lat: latitude, lng: longitude}}
      defaultZoom={13}
      onClick={openGoogleMaps}
    >
      <Marker
        icon={icon}
        position={{lat: latitude, lng: longitude}}
        onClick={openGoogleMaps}
      />
    </GoogleMap>
  )));
  
  const openGoogleMaps = () => {
    window.open(openURL, "_blank");
  };
  
  return (
    <div className={[css.map, className].join(" ")}>
      <MallowCommercialsMap
        containerElement={<div className={css.fullWidth}/>}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAi_1sGmGaeIoAvl03lXiEPWRjWePXnKzE&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div className={css.fullHeight}/>}
        mapElement={<div className={css.fullHeight}/>}
      />
    </div>
  );
};

Map.defaultProps = {
  className: ""
};

Map.propTypes = {
  className: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  openURL: PropTypes.string
};

export default Map;