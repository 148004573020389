import React from 'react';
import PropTypes from "prop-types";
import {FaRegTimesCircle} from "react-icons/fa";

import css from "./index.module.scss";


const SideDrawer = props => {
  const cssClass = props.visible ? "visible" : "hidden";
  return (
      <div className={[css[cssClass], props.className].join(" ")} style={props.style}>
        <FaRegTimesCircle className={css.closeBtn} onClick={props.onCloseBtnClick}/>
        {props.children}
      </div>
  );
};

SideDrawer.defaultProps = {
  className: "",
  visible: false
};

SideDrawer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onCloseBtnClick: PropTypes.func,
  style: PropTypes.object,
  visible: PropTypes.bool
};

export default SideDrawer;
