import React from "react";
import PropTypes from "prop-types";

import css from "./index.module.scss";


const Card = props => {
  return (
      <div className={[css[props.type], props.className].join(" ")} style={props.style}>
        {props.children}
      </div>
  );
};

Card.defaultProps = {
  className: "",
  type: "default"
};

Card.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.oneOf(["default", "inset"])
};

export default Card;