import React from 'react';
import { Link } from 'gatsby';

import Column from 'src/components/Column';
import Row from 'src/components/Row';
import Tab from 'src/components/Tab';

import {pages} from 'src/config';
import css from './index.module.scss';


class DesktopNavigation extends React.Component {
  
  renderIconComponent(Component) {
    return <Component className={css.tabIcon}/>;
  }
  
  isActiveTab(path) {
    const {location} = this.props;
    return path === '/' ? location.pathname === path : location.pathname.startsWith(path);
  }
  
  render() {
    const orderedPageKeys = Object.keys(pages).sort((a, b) => pages[a].order - pages[b].order);
    return (
      <nav className={css.desktopNavigation}>
        <Row>
          {orderedPageKeys.map(key => {
            const page = pages[key];
            return (
              <Link to={page.path} key={page.path}>
                <Tab className={this.isActiveTab(page.path) ? css.tabActive : css.tabDefault} type='inverted'>
                  <Column direction='cc'>
                    {this.renderIconComponent(page.icon.component)}
                    {page.icon.text}
                  </Column>
                </Tab>
              </Link>
            );
          })}
        </Row>
      </nav>
    );
  }
}

export default DesktopNavigation;
