// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-index-js": () => import("./../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-commercials-repairs-services-index-js": () => import("./../src/pages/commercials/repairs-&-services/index.js" /* webpackChunkName: "component---src-pages-commercials-repairs-services-index-js" */),
  "component---src-pages-commercials-sales-rentals-index-js": () => import("./../src/pages/commercials/sales-&-rentals/index.js" /* webpackChunkName: "component---src-pages-commercials-sales-rentals-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-faqs-index-js": () => import("./../src/pages/faqs/index.js" /* webpackChunkName: "component---src-pages-faqs-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-test-centre-bookings-index-js": () => import("./../src/pages/test-centre/bookings/index.js" /* webpackChunkName: "component---src-pages-test-centre-bookings-index-js" */),
  "component---src-pages-test-centre-index-js": () => import("./../src/pages/test-centre/index.js" /* webpackChunkName: "component---src-pages-test-centre-index-js" */),
  "component---src-pages-test-centre-inspections-index-js": () => import("./../src/pages/test-centre/inspections/index.js" /* webpackChunkName: "component---src-pages-test-centre-inspections-index-js" */),
  "component---src-templates-advert-index-js": () => import("./../src/templates/advert/index.js" /* webpackChunkName: "component---src-templates-advert-index-js" */)
}

