import React from "react";
import PropTypes from "prop-types";

import Backdrop from "src/components/Backdrop";
import Column from "src/components/Column";
import SideDrawer from "src/components/SideDrawer";

import css from "./index.module.scss";


const Burger = props => {
  return (
    <React.Fragment>
      <Column className={[css.burger, props.className].join(" ")} style={props.style} onClick={props.onBurgerClick}>
        <span/>
        <span/>
        <span/>
      </Column>
      <Backdrop visible={props.sideDrawerVisible} onClick={props.onBurgerClick}/>
      <SideDrawer
        className={props.sideDrawerClassName}
        onCloseBtnClick={props.onBurgerClick}
        visible={props.sideDrawerVisible}
      >
        {props.children}
      </SideDrawer>
    </React.Fragment>
  );
};

Burger.defaultProps = {
  className: ""
};

Burger.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  location: PropTypes.string,
  onBurgerClick: PropTypes.func.isRequired,
  sideDrawerClassName: PropTypes.string,
  sideDrawerVisible: PropTypes.bool.isRequired,
  style: PropTypes.object
};

export default Burger;