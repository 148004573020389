module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"C:\\Users\\Owner\\mallow-test-centre\\src\\layouts\\Default"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mallow Test Centre","short_name":"MTC","start_url":"/","background_color":"#ffffff","theme_color":"#008c99","display":"standalone","icon":"src/resources/images/app-icon.png","cache_busting_mode":"name","crossOrigin":"use-credentials","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f08c2cb08ee4efb0d2602b66d0758806"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
