import React from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import Img from "gatsby-image"

import Burger from "src/components/Burger";
import Column from "src/components/Column";
import Row from "src/components/Row";

import {pages} from "src/config";
import css from "./index.module.scss";


class MobileNavigation extends React.Component {
  state = {
    opened: false
  };
  
  toggleDrawer(opened) {
    this.setState({...this.state, opened});
  }
  
  renderIconComponent(Component) {
    return <Component className={css.linkRowIcon}/>;
  };
  
  render() {
    const {opened} = this.state;
    const orderedPageKeys = Object.keys(pages).sort((a, b) => pages[a].order - pages[b].order);
  
    return (
      <StaticQuery
        query={graphql`
          query {
            logo: file(relativePath: { eq: "images/test-centre/logo-white-text.png" }) {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={data => (
          <nav className={css.mobileNavigation}>
            <Burger
              className={css.burger}
              location={this.props.location.pathname}
              onBurgerClick={this.toggleDrawer.bind(this, !opened)}
              sideDrawerClassName={css.sideDrawer}
              sideDrawerVisible={opened}
            >
              <Column className={css.sideDrawerContent} direction="cc">
                <Link to='/' className={css.logoImg} onClick={this.toggleDrawer.bind(this, !opened)}>
                  <Img fluid={data.logo.childImageSharp.fluid}/>
                </Link>
                <hr/>
                {orderedPageKeys.map(key => {
                  const page = pages[key];
                  return (
                    <React.Fragment key={page.path}>
                      <Link className={css.linkRow} to={page.path} onClick={this.toggleDrawer.bind(this, !opened)}>
                        <Row direction="cc">
                          <h3>{this.renderIconComponent(page.icon.component)}</h3>
                          <h3>{page.icon.text}</h3>
                        </Row>
                      </Link>
                      <hr/>
                    </React.Fragment>
                  );
                })}
              </Column>
            </Burger>
          </nav>
        )}
      />
    );
  }
  
}

export default MobileNavigation;
